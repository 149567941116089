.filepond--wrapper {

  height: 100%;

  .filepond--root {
    height: 100%;
    cursor: pointer;

    .filepond--drop-label {
      height: 100%;
    }
  }
}

.leaflet-container {
  height: calc(100vh - 108px);

  &.inline {
    height: calc(100vh - 288px);
    margin-bottom: 16px;
  }
}

.leaflet-popup-content {
  p {
    margin: 0;
  }
  .MuiListItem-root {
    padding: 0;
    margin: 0;
  }
  .MuiListSubheader-root {
    padding: 0;
    margin: 0;
    line-height: 16px;
  }
}

.sr-only {
  display: none;
}
